<template>
  <div class="recitationMode">
    <div class="width_1200">
      <div class="bread">
        <bread-crumb></bread-crumb>
      </div>
    </div>
    <div class="width_1200">
      <topic-paper></topic-paper>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/breadcrumb.vue";
import topicPaper from "@/components/topicPaper.vue";

export default {
  data() {
    return {
    };
  },
  components: {
    BreadCrumb,
    topicPaper
  },
  created() {
  },
  mounted() {},
  methods: {
  },
};
</script>

<style lang="less" scoped>
.recitationMode {
  // margin-top: -30px;
  background-color: #f5f5f5;
  font-size: 14px;
}
</style>